import Furigana from '../../Resources/Furigana/Furigana';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBackward, faRotateRight, faForward, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ReactSlider from 'react-slider'
import { useEffect, useRef, useState } from 'react';
import './Vocabulario.css';

function VocabActividad() {

    type Vocabulario = {
        furigana: string;
        tipo: string;
        kanji: string;
        speech: string;
        word: string;
        traduccion: string;
        traduccionEjemplo: string;
        ejemplo: string;
        imagen: string;
        id: number;
    };

    const [furigana, setFurigana] = useState(true);
    const [translate, setTranslate] = useState(true);
    const [autoplay, setAutoplay] = useState(true);
    const [random, setRandom] = useState(false);
    const [sliderValue, setSliderValue] = useState([0, 0]);
    const [total, setTotal] = useState(-1);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [kana, setKana] = useState(['かん*じ', '漢*字', 'かんじ', 'kanji', '例文', 'traducción','漢字']);

    let lecciones = useRef([]);
    let index = useRef(0);
    let autoplay_value = useRef(true);

    const navigate = useNavigate();
    const location = useLocation();
    const queryString = location.search;
    const stringQuery = new URLSearchParams(queryString).get('index') || '';

    const loadLecciones = async () => {
        try {
            const lecciones = await import(`../../Resources/Vocabulario/${stringQuery}.json`);
            return lecciones.default;
        } catch (error) {
            console.error('Error loading lecciones:', error);
            return [];
        }
        autoplay_value.current = autoplay;
    };

    const showKana = (_kana: Vocabulario) => {
        let kana = _kana.kanji;
        let furigana = _kana.furigana;
        let speech = _kana.speech;
        let word = _kana.word;
        let traduccion = _kana.traduccion;
        let ejemplo = _kana.ejemplo;
        let traduccionEjemplo = _kana.traduccionEjemplo;
        setKana([furigana, kana, speech, traduccion, ejemplo, traduccionEjemplo, word]);
    };

    const speakJap = () => {
        let utterance = new SpeechSynthesisUtterance(`${kana[2]}。${kana[4]}`);
        // utterance.lang = 'ja-JP';
        utterance.lang = 'ja-JP';
        utterance.onstart = () => {
            console.log('Speech started');
        };
        utterance.onend = () => {
            console.log('Speech ended');
        };
        utterance.onerror = (event) => {
            console.error('Speech synthesis error:', event.error);
        };
        utterance.onboundary = (event) => {
            console.log('Speech boundary:', event);
        };
        utterance.onmark = (event) => {
            console.log('Speech mark:', event);
        };
        utterance.onpause = () => {
            console.log('Speech paused');
        };
        utterance.onresume = () => {
            console.log('Speech resumed');
        };
        speechSynthesis.speak(utterance);
    }

    const setBoldkana = (text: string, kana: string) => {
        let bold = `<b style='color:red;'>${kana}</b>`;
        let newText = `<p>${text.replace(kana, bold)}</p>`;
        return newText;
    }

    useEffect(() => {
        const fetchData = async () => {
            lecciones.current = await loadLecciones();
            setTotal(lecciones.current.length - 1);
            setSliderValue([0, lecciones.current.length - 1])
            setMin(0);
            setMax(lecciones.current.length - 1);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (total > -1) {
            if (autoplay)
                speakJap();
        }
    }, [kana, autoplay]);

    useEffect(() => {
        if (total > -1) {
            if (lecciones.current[0] != undefined) {
                // console.log(lecciones.current[0]);
                showKana(lecciones.current[0]);
            }
        }
    }, [total]);

    const handleToggleFurigana = () => {
        setFurigana(!furigana);
    };

    const handleToggleTranslate = () => {
        setTranslate(!translate);
    };

    const handleToggleAutoPlay = () => {
        setAutoplay(!autoplay);
    };

    const handleToggleRandom = () => {
        setRandom(!random);
    };

    const handleButtonPlay = () => {
        speakJap();
    }

    const handleSliderBeforeChange = () => {
        autoplay_value.current = JSON.parse(JSON.stringify(autoplay));
    };

    const handleSliderChange = (value: number[]) => {
        setSliderValue(value);
        // console.log(value);
        index.current = value[0];
        showKana(lecciones.current[index.current]);

        setAutoplay(false);
        setMin(value[0]);
        setMax(value[1]);
    };

    const handleSliderAfterChange = () => {
        setAutoplay(autoplay_value.current);
    }

    const handleButtonBack = () => {
        if (index.current > min) {
            index.current -= 1;
            // console.log(lecciones.current[index.current]);
            showKana(lecciones.current[index.current]);
        }
    };

    const handleButtonRandom = () => {
        if (random) {
            index.current = Math.floor(Math.random() * (max - min + 1)) + min;
            if (lecciones.current[0] != undefined) {
                // console.log(lecciones.current[index.current]);
                showKana(lecciones.current[index.current]);
            }
        }
    }

    const handleButtonFront = () => {
        if (index.current < max) {
            index.current += 1;
            // console.log(lecciones.current[index.current]);
            showKana(lecciones.current[index.current]);
        }
    };

    return (
        <div className="App">
            <div className="VocabActividad">
                <section className='head'>
                    <button className='back-button button-control' onClick={() => navigate(-1)}>
                        <div>
                            <FontAwesomeIcon icon={faArrowLeft} className='Icon-color' />
                        </div>
                    </button>
                </section>
                <section className='config'>
                    <div className='config-switch'>
                        <label className="switch">
                            <input type="checkbox" checked={furigana} onChange={handleToggleFurigana} />
                            <span className="slider round"></span>
                        </label>  furigana
                    </div>
                    <div className='config-switch'>
                        <label className="switch">
                            <input type="checkbox" checked={translate} onChange={handleToggleTranslate} />
                            <span className="slider round"></span>
                        </label>  traducción
                    </div>
                    <div className='config-switch'>
                        <label className="switch">
                            <input type="checkbox" checked={autoplay} onChange={handleToggleAutoPlay} />
                            <span className="slider round"></span>
                        </label>  auto play
                    </div>
                    <div className='config-switch'>
                        <label className="switch">
                            <input type="checkbox" checked={random} onChange={handleToggleRandom} />
                            <span className="slider round"></span>
                        </label>  aleatorio
                    </div>
                </section>
                <section className='exersice'>
                    {/* ejemplo de furigana */}
                    <Furigana
                        furigana={kana[0]}
                        kana={kana[1]}
                        showfuri={furigana}
                    />
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: setBoldkana(kana[4],kana[6])}} />
                        <p className={`section ${translate ? '' : 'hidden'}`}>{kana[5]}</p>
                    </div>
                    <div>
                        <button className='button-control' onClick={handleButtonPlay}>
                            <FontAwesomeIcon icon={faCaretRight} className='Icon-color' />
                        </button>
                    </div>
                    <p className={`section ${translate ? '' : 'hidden'}`}>{kana[3]}</p>
                </section>
                <section className='controles'>
                    <div>
                        {/* la minDistance NO debe ser menor que max (total) */}
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            value={sliderValue}
                            ariaLabelledby={['first-slider-label', 'second-slider-label']}
                            pearling
                            minDistance={4}
                            defaultValue={sliderValue}
                            max={total}
                            onBeforeChange={handleSliderBeforeChange}
                            onChange={handleSliderChange}
                            onAfterChange={handleSliderAfterChange}
                        />
                    </div>
                    <div className='next'>
                        <div className={`section ${!random && index.current > min ? '' : 'hidden'}`}>
                            <button className='button-control' onClick={handleButtonBack}>
                                <FontAwesomeIcon icon={faBackward} className='Icon-color' />
                            </button>
                        </div>
                        <div className={`section ${random ? '' : 'hidden'}`}>
                            <button className='button-control' onClick={handleButtonRandom}>
                                <FontAwesomeIcon icon={faRotateRight} className='Icon-color' />
                            </button>
                        </div>
                        <div className={`section ${!random && index.current < max ? '' : 'hidden'}`}>
                            <button className='button-control' onClick={handleButtonFront}>
                                <FontAwesomeIcon icon={faForward} className='Icon-color' />
                            </button>
                        </div>
                    </div>
                </section>
            </div >
        </div >
    );
}

export default VocabActividad;