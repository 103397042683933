import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBookOpen, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import './Indice.css';

function Indice() {
    const navigate = useNavigate();
    return (
        <div className="App">
            <div className='Indice'>
                <div>
                    <h1>Práctica Japones</h1>
                </div>
                <div className='Index_list'>
                    <button className='Button-app' onClick={(e)=>{navigate('../vocabulario');}}>
                        <div style={{ flex: '30%' }}>
                            <FontAwesomeIcon icon={faBars} className='Icon-color' />
                        </div>
                        <div style={{ flex: '70%' }}>
                            <p>Vocabulario</p>
                        </div>
                    </button>
                    <button className='Button-app' onClick={(e)=>{navigate('../lectura');}}>
                        <div style={{ flex: '30%' }}>
                            <FontAwesomeIcon icon={faBookOpen} className='Icon-color' />
                        </div>
                        <div style={{ flex: '70%' }}>
                            <p>Lectura</p>
                        </div>
                    </button>
                    <button className='Button-app' onClick={(e)=>{navigate('../lectura_test');}}>
                        <div style={{ flex: '30%' }}>
                            <FontAwesomeIcon icon={faPenToSquare} className='Icon-color' />
                        </div>
                        <div style={{ flex: '70%' }}>
                            <p>Gramática</p>
                        </div>
                    </button>
                </div>
            </div>
        </div >
    );
}
export default Indice;