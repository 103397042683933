import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBackward, faRotateRight, faForward, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ReactSlider from 'react-slider'
import { useEffect, useRef, useState } from 'react';
import './Lectura.css';

declare global {
    interface Window {
        utterances: any[];
    }
}

window.utterances = [];

function LecturabActividad() {

    type Lectura = {
        speech: string;
        traduccion: string;
        id: number;
    };

    const [furigana, setFurigana] = useState(true);
    const [translate, setTranslate] = useState(true);
    const [autoplay, setAutoplay] = useState(true);
    const [random, setRandom] = useState(false);
    const [sliderSpeedValue, setSliderSpeedValue] = useState(100);
    const [sliderValue, setSliderValue] = useState([0, 0]);
    const [total, setTotal] = useState(-1);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [kana, setKana] = useState(['', '']);
    const [highlightText, setHighlightText] = useState("");

    let lecciones = useRef([]);
    let index = useRef(0);
    let autoplay_value = useRef(true);

    const navigate = useNavigate();
    const location = useLocation();
    const queryString = location.search;
    const stringQuery = new URLSearchParams(queryString).get('index') || '';

    let speed = useRef(1);

    function isKanji(ch: string) {
        return /[\u4e00-\u9faf]/.test(ch);
    }

    const loadLecciones = async () => {
        try {
            const lecciones = await import(`../../Resources/Lectura/${stringQuery}.json`);
            return lecciones.default;
        } catch (error) {
            console.error('Error loading lecciones:', error);
            return [];
        }
        autoplay_value.current = autoplay;
    };

    const showKana = (_kana: Lectura) => {
        let speech = _kana.speech;
        let traduccion = _kana.traduccion;
        setKana([speech, traduccion]);
        setHighlightText(speech);
    };

    let inc = 0;
    const speakJap = () => {
        let utterance:SpeechSynthesisUtterance = new SpeechSynthesisUtterance(kana[0]);
        utterance.lang = 'ja-JP';
        utterance.rate = sliderSpeedValue / 100; // Adjust the value to set the desired speed
        utterance.onboundary = (event: SpeechSynthesisEvent) => {
            console.log('boundary', event.charIndex, event.charLength);
            let text = kana[0];
            let boundary = kana[0].substring(event.charIndex, event.charIndex + event.charLength);
            let bold = `<b style='color:red;'>${boundary}</b>`;
            let newText = text.replace(boundary, bold);
            setHighlightText(newText);
        };
        utterance.onend = (event: SpeechSynthesisEvent) => {
            let text = kana[0];
            setHighlightText(text);
            console.log('end');
        };
        utterance.onerror = (event: SpeechSynthesisErrorEvent) => {
            console.log(event.error);
        };
        utterance.onstart = (event: SpeechSynthesisEvent) => {
            console.log('start', event.charIndex, event.charLength);
        };
        utterance.onpause = (event: SpeechSynthesisEvent) => {
            console.log('pause');
        };
        utterance.onresume = (event: SpeechSynthesisEvent) => {
            console.log('resume');
        };
        utterance.onmark = (event: SpeechSynthesisEvent) => {
            console.log('mark');
        };
        window.utterances.push( utterance );
        speechSynthesis.speak(utterance);
    }

    useEffect(() => {
        const fetchData = async () => {
            lecciones.current = await loadLecciones();
            setTotal(lecciones.current.length - 1);
            setSliderValue([0, lecciones.current.length - 1])
            setMin(0);
            setMax(lecciones.current.length - 1);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (total > -1) {
            if (autoplay)
                speakJap();
        }
    }, [kana, autoplay]);

    useEffect(() => {
        if (total > -1) {
            if (lecciones.current[0] != undefined) {
                // console.log(lecciones.current[0]);
                showKana(lecciones.current[0]);
            }
        }
    }, [total]);

    const handleToggleFurigana = () => {
        setFurigana(!furigana);
    };

    const handleToggleTranslate = () => {
        setTranslate(!translate);
    };

    const handleToggleAutoPlay = () => {
        setAutoplay(!autoplay);
    };

    const handleToggleRandom = () => {
        setRandom(!random);
    };

    const handleSliderSpeedChange = (value: number) => {
        setSliderSpeedValue(value);
    };

    const handleButtonPlay = () => {
        speakJap();
    }

    const handleSliderBeforeChange = () => {
        autoplay_value.current = JSON.parse(JSON.stringify(autoplay));
    };

    const handleSliderChange = (value: number[]) => {
        setSliderValue(value);
        // console.log(value);
        index.current = value[0];
        showKana(lecciones.current[index.current]);

        setAutoplay(false);
        setMin(value[0]);
        setMax(value[1]);
    };

    const handleSliderAfterChange = () => {
        setAutoplay(autoplay_value.current);
    }

    const handleButtonBack = () => {
        if (index.current > min) {
            index.current -= 1;
            // console.log(lecciones.current[index.current]);
            showKana(lecciones.current[index.current]);
        }
    };

    const handleButtonRandom = () => {
        if (random) {
            index.current = Math.floor(Math.random() * (max - min + 1)) + min;
            if (lecciones.current[0] != undefined) {
                // console.log(lecciones.current[index.current]);
                showKana(lecciones.current[index.current]);
            }
        }
    }

    const handleButtonFront = () => {
        if (index.current < max) {
            index.current += 1;
            // console.log(lecciones.current[index.current]);
            showKana(lecciones.current[index.current]);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<Element>) => {
        switch (event.key) {
            case "ArrowRight":
                // Handle right arrow key press
                if (index.current < max) {
                    index.current += 1;
                    // console.log(lecciones.current[index.current]);
                    showKana(lecciones.current[index.current]);
                }
                break;
            case "ArrowLeft":
                // Handle left arrow key press
                if (index.current > min) {
                    index.current -= 1;
                    // console.log(lecciones.current[index.current]);
                    showKana(lecciones.current[index.current]);
                }
                break;
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown as any);
        return () => {
            window.removeEventListener("keydown", handleKeyDown as any);
        };
    }, []);

    return (
        <div className="App">
            <div className="LecturaActividad">
                <section className='head'>
                    <button className='back-button button-control' onClick={() => navigate(-1)}>
                        <div>
                            <FontAwesomeIcon icon={faArrowLeft} className='Icon-color' />
                        </div>
                    </button>
                </section>
                <section className='config'>
                    <div className='config-switch'>
                        <label className="switch">
                            <input type="checkbox" checked={furigana} onChange={handleToggleFurigana} />
                            <span className="slider round"></span>
                        </label>  furigana
                    </div>
                    <div className='config-switch'>
                        <label className="switch">
                            <input type="checkbox" checked={translate} onChange={handleToggleTranslate} />
                            <span className="slider round"></span>
                        </label>  traducción
                    </div>
                    <div className='config-switch'>
                        <label className="switch">
                            <input type="checkbox" checked={autoplay} onChange={handleToggleAutoPlay} />
                            <span className="slider round"></span>
                        </label>  auto play
                    </div>
                    <div className='config-switch'>
                        <label className="switch">
                            <input type="checkbox" checked={random} onChange={handleToggleRandom} />
                            <span className="slider round"></span>
                        </label>  aleatorio
                    </div>
                    <div className='config-switch'>
                        <div className="speed">
                            <p>velocidad</p>
                            <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="example-thumb"
                                trackClassName="example-track2"
                                defaultValue={sliderSpeedValue}
                                max={100}
                                min={50}
                                onChange={handleSliderSpeedChange}
                            />
                        </div>
                    </div>
                </section>
                <section className='exersice'>
                    {/* ejemplo de furigana */}
                    <div onKeyDown={handleKeyDown}>
                        <div style={{ fontSize: 18 }} dangerouslySetInnerHTML={{ __html: highlightText }} />
                    </div>
                    <div>
                        <button className='button-control' onClick={handleButtonPlay}>
                            <FontAwesomeIcon icon={faCaretRight} className='Icon-color' />
                        </button>
                    </div>
                    <p className={`section ${translate ? '' : 'hidden'}`}>{kana[1]}</p>
                </section>
                <section className='controles'>
                    <div>
                        {/* la minDistance NO debe ser menor que max (total) */}
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            value={sliderValue}
                            ariaLabelledby={['first-slider-label', 'second-slider-label']}
                            pearling
                            minDistance={4}
                            defaultValue={sliderValue}
                            max={total}
                            onBeforeChange={handleSliderBeforeChange}
                            onChange={handleSliderChange}
                            onAfterChange={handleSliderAfterChange}
                        />
                    </div>
                    <div className='next'>
                        <div className={`section ${!random && index.current > min ? '' : 'hidden'}`}>
                            <button className='button-control' onClick={handleButtonBack}>
                                <FontAwesomeIcon icon={faBackward} className='Icon-color' />
                            </button>
                        </div>
                        <div className={`section ${random ? '' : 'hidden'}`}>
                            <button className='button-control' onClick={handleButtonRandom}>
                                <FontAwesomeIcon icon={faRotateRight} className='Icon-color' />
                            </button>
                        </div>
                        <div className={`section ${!random && index.current < max ? '' : 'hidden'}`}>
                            <button className='button-control' onClick={handleButtonFront}>
                                <FontAwesomeIcon icon={faForward} className='Icon-color' />
                            </button>
                        </div>
                    </div>
                </section>
            </div >
        </div >
    );
}

export default LecturabActividad;