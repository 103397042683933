import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../Resources/logo.png';
import './Intro.css';

function Intro() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('Indice');
    }, 3000);
  }, []);

  return (

    < div className="App" >
      <div className='Intro'>
        < header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
      </div>
    </div >
  );
}
export default Intro;