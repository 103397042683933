import React from 'react';
import './Furigana.css';

const Furigana = ({ furigana, kana, showfuri }: { furigana: string, kana: string, showfuri: boolean }) => {

    let htmlContent: string = '';
    let myClass_name = showfuri ? '' : 'hidden';
    let kanainc = 0;
    const arrayfurigana = furigana.split('*');
    const startruby = '<ruby>';
    const endruby = '</ruby>';
    if (kana != undefined) {
        const arrayrkana = kana.split('*');
        htmlContent += '<p>'
        for (let i = 0; i < arrayrkana.length; i++) {
            let kana = arrayrkana[i];
            // htmlContent += startruby
            if (isKanji(kana)) {
                htmlContent += `<ruby>${kana}<rt class=${myClass_name}> ${arrayfurigana[kanainc]}</rt></ruby>`
                kanainc++;
            } else {
                htmlContent += `<ruby>${kana}</ruby>`;
            }
        }
        htmlContent += '</p>';
    }
    else
        htmlContent = `<p><ruby> ${furigana} <rt class=hidden> ${furigana}</rt></ruby></p>`;
    console.log(htmlContent);

    function isKanji(ch: string) {
        return /[\u4e00-\u9faf々]/.test(ch);
    }

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
};

export default Furigana;
