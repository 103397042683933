import { useState } from 'react';
import './Lectura.css';

function TextHighlighter() {
    const read_text = {
        "speech": "私は木村さんに花をあげました。",
        "split_text":'私*は*木村*さん*に*花*を*あげ*まし*た。',
        "furigana": "わたし*は*きむら*さん*に*はな*を*あげ*まし*た。",
        "traduccion": "Le di flores a Kimura-san",
        "id": 5
    }
    const showfuri = false;
    const words = read_text.split_text.split('*');
    const [highlightIndex, setHighlightIndex] = useState(0);
    const synth = window.speechSynthesis;

    let inc = 0;
    const speakText = () => {
        const utterance = new SpeechSynthesisUtterance(read_text.speech);
        utterance.lang = 'ja-JP';
        utterance.rate = 1.0; // Adjust the value to set the desired speed
        utterance.onboundary = (event: SpeechSynthesisEvent) => {
            setHighlightIndex(inc);
            inc++;
        };
        utterance.onend = (event: SpeechSynthesisEvent) => {
            inc = 0;
        };
        synth.speak(utterance);
    };

    function isKanji(ch: string) {
        return /[\u4e00-\u9faf]/.test(ch);
    }

    return (
        <div>
            <p>
                {words.map((word, index) => (
                    <ruby>
                        <span key={index} style={{ fontWeight: index === highlightIndex ? 'bold' : 'normal' }}>
                            {word}
                        </span>
                        <rt className={showfuri ? '':'hidden'}>{isKanji(word) ? read_text.furigana.split('*')[index] : ''}</rt>
                    </ruby>
                ))}
            </p>
            <button onClick={speakText}>Reproducir texto</button>
        </div>
    );
}

export default TextHighlighter;
