import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './Lectura.css';
import lecciones from '../../Resources/lecciones.json';

function Lectura() {
    const navigate = useNavigate();

    return (
        <div className="App">
            <div className="Lectura">
                <section className='headerIndex'>
                    <div className='back'>
                        <button className='back-button button-control' onClick={() => navigate(-1)}>
                            <div>
                                <FontAwesomeIcon icon={faArrowLeft} className='Icon-color' />
                            </div>
                        </button>
                    </div>
                    <div className='titulo'>
                        <h1>Práctica Lectura Japones</h1>
                    </div>
                    <div className='hidden'>
                        <button className='back-button button-control' onClick={() => navigate(-1)}>
                            <div>
                                <FontAwesomeIcon icon={faArrowLeft} className='Icon-color' />
                            </div>
                        </button>
                    </div>
                </section>
                <div className='Index_list' >
                    {lecciones.map((leccion: any, index: number) => (
                        <button className='Button-app' key={index} onClick={() => navigate(`/lectura_actividad?index=${leccion.replace(/á/g, "a").replace(/é/g, "e").replace(/í/g, "i").replace(/ó/g, "o").replace(/ú/g, "u").replace(/Á/g, "A").replace(/É/g, "E").replace(/Í/g, "I").replace(/Ó/g, "O").replace(/Ú/g, "U").replace(/\s/g, "")}`)}>
                            {leccion}
                        </button>
                    ))}
                </div>
            </div>
        </div >
    );
}

export default Lectura;