import { Routes, Route } from "react-router-dom"
import  Intro  from  '../Intro/Intro';
import Indice from '../Indice/Indice';
import './App.css';
import Vocabulario from "../Vocabulario/Vocabulario";
import VocabActividad from "../Vocabulario/VocabActividad";
import Lectura from "../Lectura/Lectura";
import LecturabActividad from "../Lectura/LecturaActividad";
import TextHighlighter from "../Lectura/lectura_test2";

function App() {

  return (
    <div className="Aplicacion">
      <Routes>
        <Route path="/" element={ <Intro /> } />
        <Route path="indice" element={ <Indice /> } />
        <Route path="vocabulario" element={ <Vocabulario /> } />
        <Route path="vocab_actividad" element={ <VocabActividad /> } />
        <Route path="lectura" element={ <Lectura /> } />
        <Route path="lectura_actividad" element={ <LecturabActividad /> } />
        <Route path="lectura_test" element={ <TextHighlighter /> } />
      </Routes>
    </div>
  );
}
export default App;